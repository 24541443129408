
#selectBtn {
  display: none;
}
.card-btn-upload {
  text-align: center;
}
.card-file-list {
  text-align: center;
}

.upload-item {
  border: 1px solid rgb(197, 197, 197);
  border-radius: 0.5rem;
  //padding: 0.5rem;
  //font-size: smaller;
  float: left;
  padding: 5px;
  text-align: center;
}
.drop-container:hover {
  background: #979696ad;
}
.upload-item:hover {
  background: #e0e0e0ad;
  cursor: pointer;
  border: 1px dashed rgba(124, 2, 2, 0.616);
}

.buttons {
  display: flex;
  gap: 1rem;

  &.centered {
    justify-content: center;
  }
}

.button {
  cursor: pointer;

  color: white;
  background: #809af0;
  text-decoration: none;

  width: fit-content;
  padding: 0.5rem 1rem;
  border-radius: 2px;

  &:hover {
    background: #3f6cff;
  }

  &.disabled {
    cursor: default;
    background: lightgray;
    pointer-events: none;
  }
}

.button-danger {
  background: #f08080;

  &:hover {
    background: #f65959;
  }
}

.button-upload {
  background: #00d591;

  &:hover {
    background: #02b97f;
  }
}

.failed {
  color: red;
}

.success {
  color: green;
}

.uploading {
  color: rgb(255, 102, 0);
}

.failed-border {
  border-color: red;
}

textarea
{
  width:100%;
  background-color: #ffffffea;
  border-radius: .7rem;
}
.textwrapper
{
  border:1px solid #999999;
  margin:5px 0;
  padding:3px;
}
