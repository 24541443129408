

.upload-item {
  border: 1px solid rgb(197, 197, 197);
  border-radius: 0.5rem;
  display: grid;
  float: left;
  position: relative; 
  //padding: 0.5rem;
  //font-size: smaller;
  padding: 0px;
  text-align: center;
} 

